@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueBlack.otf") format("opentype");
  font-weight: 900; /* Black */
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueBlackItalic.otf")
    format("opentype");
  font-weight: 900; /* Black Italic */
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueBold.otf") format("opentype");
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueBoldItalic.otf") format("opentype");
  font-weight: 700; /* Bold Italic */
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueHeavy.otf") format("opentype");
  font-weight: 800; /* Heavy */
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueHeavyItalic.otf")
    format("opentype");
  font-weight: 800; /* Heavy Italic */
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueLight.otf") format("opentype");
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueLightItalic.otf")
    format("opentype");
  font-weight: 300; /* Light Italic */
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueMedium.otf") format("opentype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueMediumItalic.otf")
    format("opentype");
  font-weight: 500; /* Medium Italic */
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueRoman.otf") format("opentype");
  font-weight: 400; /* Roman */
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueThin.otf") format("opentype");
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public//fonts/HelveticaNeueThinItalic.otf") format("opentype");
  font-weight: 100; /* Thin Italic */
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue UL";
  src: url("../../public//fonts/HelveticaNeueUltraLight.otf") format("opentype");
  font-weight: 10; /* Ultra Light */
  font-style: normal;
}

body {
  font-family: "Helvetica Neue", sans-serif; /* Default font */
  background-color: #fffef7;
}

.empty-space {
  height: 10vh;
}

.full-screen-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -3.5%;
  height: 100vh;
  width: 99vw; /* Full viewport width */
  background-color: none;
  color: white; /* Customize text color */
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
}

.work-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -3.5%;
  /* height: 100vh; */
  width: 99vw; /* Full viewport width */
  background-color: none;
  color: white; /* Customize text color */
  text-align: center;
  overflow-y: hidden;
}

.contactee {
  color: #000;
}

.full-screen-section h1 {
  font-weight: 500;
  font-size: 10em;
  letter-spacing: -0.03em;
  z-index: 1;
}

.full-screen-section h2 {
  font-family: "Helvetica Neue UL";
  font-weight: 100;
  font-size: 1.8em;
  letter-spacing: 0.3em;
  padding-left: 10px;
  z-index: 1;
  color: black; /* Customize text color */
}

.full-screen-section p {
  font-family: "Helvetica Neue UL";
  font-weight: 10;
  font-size: 2.4em;
  letter-spacing: 0.32em;
  padding-left: 17px;
  margin-top: -30px;
  z-index: 1;
}

.full-screen-section h3 {
  font-family: "Helvetica Neue UL";
  font-weight: 10;
  font-size: 2.1em;
  letter-spacing: 0.3em;
  padding-left: 17px;
  margin-top: -30px;
  z-index: 1;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure video covers the background */
  z-index: -1; /* Place video behind text */
}

.mButton {
  font-family: "Helvetica Neue UL";
  height: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 60px;
  width: 280px;
  background-color: #fffef7; /* Default background color */
  transition: background-color 0.3s ease; /* Smooth transition for background change */
}

.mButton:hover {
  background-color: white; /* Background color on hover */
}

.mLinks {
  font-family: "Helvetica Neue UL";
  font-size: 1.5em;
  letter-spacing: 0.32em;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.menu-list {
  flex-direction: column;
  list-style-type: none; /* Remove markers */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  display: flex; /* Optional: if you want them in a row */
}

.cards {
  /* padding: 1rem; */
  background: #fffef7;
  overflow-x: hidden;
  width: 98%;
  overflow-y: hidden;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  /* max-width: 2048px; */
  width: 100%;
  margin: 0 auto;
  overflow-y: hidden; /* Prevent vertical scrolling within cards */
}

.cards__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow: hidden;
  width: 100%;
  /* margin: 50px 0 45px; */
}

.cards__items {
  /* margin-bottom: 24px; */
  width: 100%;
  padding: 5px;
  overflow-x: hidden;
  overflow-y: hidden; /* Prevent vertical scrolling within cards */
}

.cards__item {
  display: flex;
  flex: 1;
  overflow-x: hidden;
  overflow-y: hidden; /* Prevent vertical scrolling within cards */
  /* margin: 0 1rem; */
  /* padding-left: 10px; */
}

.lastOne {
  padding-bottom: 150px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  /* border-radius: 4px; */
  overflow-x: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 97%;
  padding-top: 55%;
  /*before 67%*/
  overflow-x: hidden;
  /* border-radius: 8px; */
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  /*/color: #fff;
    background-color: #000;/*/
  box-sizing: border-box;
  overflow-x: hidden;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.02);
  filter: saturate(140%);
}

.cards__item__video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow-x: hidden;
  transition: all 0.2s linear;
}

.overlay-text {
  position: absolute;
  font-family: "Helvetica Neue", sans-serif; /* Default font */
  letter-spacing: 0.3em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2em; /* Adjust font size as needed */
  font-weight: bold;
  text-align: center;
  padding: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  border-radius: 5px;
}

.cards__item__video:hover {
  transform: scale(1.02);
  filter: saturate(140%);
  overflow-x: hidden;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  align-content: left;
  padding: 40px;
  font-family: Arial, sans-serif;
  text-align: left;
  width: 100%;
  height: 50%;
  border-right: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.contact-left,
.contact-right {
  flex: 1;
}

.contact-left {
  padding-right: 20px;
}

.keep-in-touch {
  font-size: 0.9em;
  font-weight: bold;
  color: #888;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.contact-left h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  font-size: 1em;
}

.contact-form textarea {
  height: 100px;
  resize: none;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #aaa;
  color: white;
  border: none;
  cursor: pointer;
}

.contact-right {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-left: 20px;
  font-size: 0.9em;
}

.contact-info p {
  margin: 5px 0;
}

.contact-info p:first-child {
  font-weight: bold;
  color: #888;
}

@media screen and (max-width: 820px) {
  .full-screen-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    color: white;
    text-align: center;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  .contactee {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: left;
    font-size: 0.8em;
    color: #000;
  }

  .contact-info {
    padding-top: 30px;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .contact-container {
    flex-direction: column;
    overflow-x: visible;
    overflow-y: hidden;
  }

  .contact-left {
    align-content: left;
    padding-right: 0px;
  }

  .contact-right {
    visibility: hidden;
  }

  .empty-space {
    height: 5px;
  }

  .full-screen-section h1 {
    font-weight: 500;
    font-size: 3em;
  }

  .full-screen-section h2 {
    font-weight: 100;
    font-size: 1.1em;
    letter-spacing: 0.24em;
    width: 95%;
  }

  .full-screen-section p {
    font-weight: 100;
    font-size: 0.75em;
    letter-spacing: 0.35em;
    padding-left: 10px;
    margin-top: 0px;
  }

  .cards {
    width: 100%;
    overflow-x: hidden;
  }

  .cards__container {
    width: 100%;
    overflow-x: hidden;
  }

  .cards__items {
    width: 100%;
    overflow-x: hidden;
  }

  .cards__item {
    width: 100%;
    overflow-x: hidden;
  }

  .cards__wrapper {
    width: 100%;
    overflow-x: hidden;
  }
}
